/**
 * This file contains static description text for apps.
 * I tried (really tried) to put these into .md files and load them dynamically but `import("./md/...")` does not like me. :(
 */

export const homeAppDescription = `
Welcome to labs.jhuang.ca!

My name is Jarrett, a software engineer from Canada. This site is where I put my coding projects and experiments.

If you're interested in contacting me or learning more about me, please visit my [personal website](https://jhuang.ca) for more information.
`;

export const colourPickerAppDescription = `
Ever had trouble finding a "good colour" when you're writing UI code?

This app was created a while ago for me to quickly generate a random colour with a name. I've used it extensively over the years.
Credits go to [Ntc.js](https://chir.ag/projects/ntc/).

I sense a lot of more advanced features coming to this app. Stay tuned!
`;

export const threeAppDescription = `
I recently fell in love with [Three.js](https://threejs.org/). (and you should too!)

This app is our love child.
`;

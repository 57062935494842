import "./assets/css/Home.css";
import raninbowLogo from "./assets/img/rainbow.png";
import jsonLogo from "./assets/img/code.png";
import jhLogo from "./../../assets/img/jh.png";
import jhLogoWide from "./../../assets/img/jhLabsLogoWideVector.png";
import cubeLogo from "./assets/img/icecube.png";
import widgetsLogo from "./assets/img/widgets.png";
import WaveUi from "../common/ui/wave/Wave";
import { useState } from "react";

function Home(props: any) {
  const titleBlock = () => {
    return (
      <div className="block large title-block">
        <div className="logo-container">
          <a href="https://jhuang.ca" target="_blank">
            <img className="jh-logo desktop-only" src={jhLogo} alt="Logo" />
          </a>
          <img
            className="jh-logo wide mobile-only"
            src={jhLogoWide}
            alt="Logo"
          />
        </div>
        <span className="title-content desktop-only">
          Jarrett Huang's
          <br />
          Software Labs
        </span>
      </div>
    );
  };

  const jsonViewerApp = () => {
    return (
      <div
        className="block rectangle-vertical project"
        onClick={() => window.location.assign("https://jsonviewer.io")}
      >
        <img
          className="app-logo mobile-only logo-xl"
          src={jsonLogo}
          alt="Logo"
        />
        <div className="app-name json">
          <span className="brace-open">{"{"}</span>
          <span className="json-viewer">{"JSON: Viewer"}</span>
          <span className="brace-close">{"}"}</span>
        </div>
      </div>
    );
  };

  const colourPickerApp = () => {
    return (
      <div
        className="block project"
        onClick={() => props.navigate("colour-picker")}
      >
        <img className="app-logo" src={raninbowLogo} alt="Logo" />
        <span className="app-name">Colour Picker</span>
      </div>
    );
  };

  const threeApp = () => {
    return (
      <div className="block project" onClick={() => props.navigate("three")}>
        <img className="app-logo" src={cubeLogo} alt="Logo" />
        <span className="app-name">Three</span>
      </div>
    );
  };

  const widgetsApp = () => {
    return (
      <div className="block project rectangle" onClick={() => window.location.assign("https://widgets.jhuang.ca")}>
        <img className="app-logo" src={widgetsLogo} alt="Logo" />
        <span className="app-name">Widgets</span>
      </div>
    );
  };

  const [isAboutMeHovered, setAboutMeHovered] = useState(false);
  const aboutMe = () => {
    return (
      <div
        className="block project rectangle"
        onMouseEnter={() => setAboutMeHovered(true)}
        onMouseLeave={() => setAboutMeHovered(false)}
        onTouchStart={() => setAboutMeHovered(true)}
        onTouchEnd={() => setAboutMeHovered(false)}
        onClick={() => window.open("https://jhuang.ca", "_blank")}
      >
        <img
          className="app-logo image logo-xl"
          src="https://avatars2.githubusercontent.com/u/13912692"
          alt="JH"
        />
        <WaveUi
          className="app-name font-xl"
          text="about me"
          isHovered={isAboutMeHovered}
        ></WaveUi>
      </div>
    );
  };

  return (
    <div className="Home">
      <div className="block-container">
        {titleBlock()}
        {jsonViewerApp()}
        {colourPickerApp()}
        {threeApp()}
        {widgetsApp()}
        {/* {aboutMe()} */}
      </div>
    </div>
  );
}

export default Home;
